function optionEquivalent() {
    const $amts = document.querySelectorAll('.js-amount');
    const $target = document.querySelector('.js-equivalent-target');

    if (!$amts || !$target) {
        return null;
    }

    $target.classList.add('hidden');

    $amts.forEach((el) => {
        el.addEventListener('change', (e) => {
            $target.classList.remove('hidden');
            $target.innerHTML = e.target.dataset.equivalent
                .replace(' *', ' <strong>')
                .replace('* ', '</strong> ');
        });
    });
}

export default optionEquivalent;

import React, { Fragment } from 'react';
import { useController, useForm, Controller } from 'react-hook-form';
import Input from './Input';
import Select from './Select';
import { HeartIcon } from '@heroicons/react/outline';
import axios from 'axios';
import ErrorMessage from './ErrorMessage';
import PaymentLogo from './PaymentLogo';
import { isIE, isSafari } from '../utils/browserDetection';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone';
import InputMask from 'react-input-mask';
import Datalist from './Datalist';
import InfoIcon from '../images/awesome-info-circle.svg';

const DonateOption = ({ id, currency, label, value, name, field }) => {
    return (
        <Fragment>
            <input
                className="hidden"
                hidden
                type="radio"
                id={id}
                {...field}
                value={value}
                checked={Number(field.value) === Number(value)}
            />
            <label tabIndex="0" className="tab p-2" htmlFor={id}>
                {Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: currency,
                }).format(label)}
                <div className="arrow-up absolute text-primary-lightest -bottom-3"></div>
            </label>
        </Fragment>
    );
};

const DATE_PATTERN = {
    value: /([12]\d{3}\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01]))/,
    message: 'Invalid date format. It must be YYYY/MM/DD',
};

const REDIRECT_MSG = `You will be redirected to our payment partner. If your donor information is different from your billing information, you can change your billing information while you are transacting in our payment partner's system.`;

const DonateForm = ({
    donationOptions,
    page,
    donation,
    token,
    countries,
    donationChannels,
    states,
    surveyChannels,
}) => {
    const { currency } = donation;
    const dateUnsupported = isSafari() || isIE();
    const optAmts = donationOptions.map((o) => Number(o.amount));
    const max = !!optAmts.length ? Math.max(...optAmts) : '';
    const currentDate = new Date();
    const currentYear = currentDate.getUTCFullYear();
    const currentMonth = currentDate.getUTCMonth() + 1;
    const currentDay = currentDate.getUTCDate();
    const isPH = donation.currency === 'PHP';

    const schema = yup.object().shape({
        amount: yup.lazy(() =>
            !!isPH
                ? yup
                      .number()
                      .typeError('amount must be a number')
                      .positive('amount must be more than zero')
                      .min(100, 'Amount must be at least 100')
                      .required()
                : yup
                      .number()
                      .typeError('amount must be a number')
                      .positive('amount must be more than zero')
                      .required()
        ),

        salutation: yup.string().required(),
        first_name: yup
            .string()
            .matches(/^([^0-9]*)$/, 'Must not input numbers.')
            .required(),
        last_name: yup
            .string()
            .matches(/^([^0-9]*)$/, 'Must not input numbers.')
            .required(),
        birth_year: yup
            .number()
            .typeError('birth year must be a number')
            .min(1900, 'Invalid birth year')
            .max(currentYear, 'Invalid birth year')
            .required(),
        birth_month: yup
            .number()
            .typeError('birth month must be a number')
            .when('birth_year', {
                is: (birth_year) => birth_year === currentYear,
                then: yup
                    .number()
                    .nullable()
                    .max(currentMonth, 'Must not be in the future.')
                    .transform((value, originalValue) =>
                        String(originalValue).trim() === '' ? null : value
                    ),
                otherwise: yup
                    .number()
                    .min(1, 'Invalid birth month')
                    .max(12, 'Invalid birth month')
                    .nullable()
                    .transform((value, originalValue) =>
                        String(originalValue).trim() === '' ? null : value
                    ),
            }),
        birth_day: yup
            .number()
            .min(1, 'Invalid birth day')
            .max(31, 'Invalid birth day')
            .typeError('day must be a number')
            .when(['birth_year', 'birth_month'], {
                is: (birth_year, birth_month) =>
                    birth_year === currentYear && birth_month === currentMonth,
                then: yup
                    .number()
                    .notOneOf([currentDay], 'Must not be the present date.')
                    .max(currentDay - 1, 'Must not be in the future.')
                    .nullable()
                    .transform((value, originalValue) =>
                        String(originalValue).trim() === '' ? null : value
                    ),
                otherwise: yup
                    .number()
                    .nullable()
                    .transform((value, originalValue) =>
                        String(originalValue).trim() === '' ? null : value
                    ),
            }),
        contact_number: yup
            .string()
            .phone('', false, 'Must be a valid phone number.')
            .required(),
        email: yup.string().email().required(),
        secondary_email: yup.string().email(),
        address_1: yup.string(),
        address_2: yup.lazy(() =>
            !!isPH
                ? yup
                    .string()
                    .required()
                : yup
                    .string()
        ),
        city: yup.string().required(),
        province: yup.string().required(),
        country: yup.string().required(),
        zipcode: yup.string(),
        // request_certificate: yup.boolean(),
        survey_channel: yup.string().required(),
        tin: yup.string().matches(/^\d{3}-\d{3}-\d{3}-\d{3}$/, {
                message: 'TIN must be a valid format',
                excludeEmptyString: true,
        }),
        // hide_tin: yup.boolean(),
        donation_type: yup.string().required(),
        privacy_policy: yup
            .boolean()
            .oneOf([true], 'Must Accept Privacy Policy'),
        // unsubscribe: yup.boolean(),
    });

    const {
        register,
        handleSubmit,
        watch,
        control,
        setError,
        setValue,
        formState: { errors, isDirty, isValid },
    } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

    const amt = watch('amount', max);
    const donationType = watch('donation_type');
    // const requestCertificate = watch('request_certificate');

    function handleError(e) {
        const { donation } = e.response.data;
        if (!donation) {
            return null;
        }

        const { errors } = donation;

        if (!errors) {
            return null;
        }

        Object.keys(errors).forEach((name) => {
            setError(name, {
                type: 'manual',
                message: errors[name].join('\n'),
            });
        });
    }

    function handleSuccess(response) {
        const { location, params, options } = response.data;
        const { method } = options || {};

        if (donationType === 'globalpay') {
            alert(REDIRECT_MSG);
        }

        if (method) {
            var form = document.createElement('form');
            document.body.appendChild(form);
            form.method = options.method;
            form.action = location;
            for (var name in params) {
                var input = document.createElement('input');
                input.type = 'hidden';
                input.name = name;
                input.value = params[name];
                form.appendChild(input);
            }

            form.submit();
            // window.location = location+'?'+ query;
        } else {
            window.location = location;
        }
    }

    const onSubmit = (data) => {
        axios
            .post('/donations', {
                format: 'json',
                donation: { ...data, currency },
                authenticity_token: token,
                page_slug: page.slug
            })
            .then(handleSuccess, handleError);
    };

    const { field } = useController({
        name: 'amount',
        control,
        rules: { required: true },
        defaultValue: max,
    });

    const opt = donationOptions.find((o) => Number(o.amount) === Number(amt));

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-6 sm:mx-0">
                <p className="font-medium">Select amount or indicate your own desired amount below</p>
                <div className="grid sm:grid-cols-2 xl:grid-cols-3 text-center py-3 gap-2">
                    {donationOptions.map((d, i) => (
                        <DonateOption
                            key={i}
                            id={d.id}
                            label={d.amount}
                            value={d.amount}
                            currency={d.currency}
                            field={field}
                        />
                    ))}
                </div>
                {opt && (
                    <div className="bg-primary-lightest rounded px-4 py-8 js-equivalent-target">
                        <p
                            className="my-0"
                            dangerouslySetInnerHTML={{
                                __html: opt.equivalent
                                    .replace(' *', ' <strong>')
                                    .replace('* ', '</strong> '),
                            }}
                        ></p>
                    </div>
                )}
                <div className="flex flex-col my-4">
                    <div className="flex flex-col sm:flex-row sm:items-center">
                        <label
                            htmlFor="amount"
                            className="block text-sm font-bold whitespace-nowrap mb-2 sm:mb-0"
                        >
                            Enter my own amount:{' '}
                            <span className="text-red-500">*</span>
                        </label>
                        <div className="sm:ml-4 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                {currency && (
                                    <span className="text-gray-500 sm:text-sm">
                                        {currency}
                                    </span>
                                )}
                            </div>
                            <input
                                type="text"
                                id="amount"
                                className="focus:ring-primary focus:border-primary block w-full pl-11 pr-4 sm:text-sm border-gray-300 rounded-md"
                                placeholder="0.00"
                                {...field}
                            />
                        </div>
                    </div>
                    <ErrorMessage name="amount" errors={errors} />
                </div>
            </div>
            <div className="p-6 xl:p-10 bg-gray-100 rounded">
                <h2 className="text-xl font-bold mb-3">My Information</h2>
                <p className="text-sm">
                    Fields marked with <span className="text-red-500">*</span>{' '}
                    are required.
                </p>
                <hr className="border-gray-300 my-4" />
                <div className="grid grid-cols-9 gap-4 mb-8">
                    <div className="col-span-full 2xl:col-span-3">
                        <Select
                            label="Salutation"
                            name="salutation"
                            required
                            register={register}
                            options={{ 'Mr.': 'Mr.', 'Ms.': 'Ms.', 'Mx.': 'Mx.', 'Others': 'Others' }}
                        />
                        <ErrorMessage name="salutation" errors={errors} />
                    </div>
                    <div className="col-span-full 2xl:col-span-3">
                        <Input
                            name="first_name"
                            required
                            label="First Name"
                            register={register}
                            placeholder="Enter first name"
                        />
                        <ErrorMessage name="first_name" errors={errors} />
                    </div>
                    <div className="col-span-full 2xl:col-span-3">
                        <Input
                            name="last_name"
                            required
                            label="Last Name"
                            register={register}
                            placeholder="Enter last name"
                        />
                        <ErrorMessage name="last_name" errors={errors} />
                    </div>
                    <div className="col-span-full grid grid-cols-9 gap-x-4">
                        <p className="col-span-full input-label">
                            Date of Birth
                        </p>
                        <div className="col-span-3">
                            <Input
                                name="birth_year"
                                required
                                label="Year"
                                maxLength={4}
                                register={register}
                                placeholder="YYYY"
                            />
                            <ErrorMessage name="birth_year" errors={errors} />
                        </div>
                        <div className="col-span-3 sm:col-span-2">
                            <Input
                                name="birth_month"
                                label="Month"
                                maxLength={2}
                                register={register}
                                placeholder="MM"
                            />
                            <ErrorMessage name="birth_month" errors={errors} />
                        </div>
                        <div className="col-span-3 sm:col-span-2">
                            <Input
                                name="birth_day"
                                label="Date"
                                maxLength={2}
                                register={register}
                                placeholder="DD"
                            />
                            <ErrorMessage name="birth_day" errors={errors} />
                        </div>
                    </div>
                    <div className="col-span-full">
                        {isPH ? (
                            <Controller
                                render={({ field }) => (
                                    <Fragment>
                                        <label
                                            htmlFor={name}
                                            className="input-label"
                                        >
                                            Contact Number <span className="text-red-500">*</span>
                                        </label>
                                        <InputMask
                                            className="input-field"
                                            mask="9999-999-9999"
                                            {...field}
                                            placeholder="0912-345-6789"
                                        />
                                    </Fragment>
                                )}
                                control={control}
                                name="contact_number"
                                defaultValue=""
                            />
                        ) : (
                            <Input
                                name="contact_number"
                                required
                                label="Contact Number"
                                register={register}
                            />
                        )}
                        <ErrorMessage name="contact_number" errors={errors} />
                    </div>
                    <div className="col-span-full">
                        <Input
                            name="email"
                            required
                            type="email"
                            label="Email Address"
                            register={register}
                            placeholder={isPH ? 'juan.delacruz@gmail.com' : ''}
                        />
                        <ErrorMessage name="email" errors={errors} />
                    </div>
                    <div className="col-span-full">
                        <Input
                            name="secondary_email"
                            type="email"
                            label="Secondary Email Address"
                            register={register}
                            placeholder={isPH ? 'another.juan@gmail.com' : ''}
                        />
                        <ErrorMessage name="secondary_email" errors={errors} />
                    </div>
                    <div className="col-span-full grid grid-cols-9 gap-x-4 gap-y-2">
                        <p className="col-span-full input-label">Address</p>
                        <div className="col-span-full">
                            <Input
                                name="address_1"
                                type="textarea"
                                label="Line 1"
                                placeholder={
                                    isPH
                                        ? 'House number or Lot/Blk/Phase, Floor number, Building or Apartment Name, Street Name, Village or Subdivision'
                                        : ''
                                }
                                register={register}
                            />
                            <ErrorMessage name="address_1" errors={errors} />
                        </div>
                        <div className="col-span-full">
                            <Input
                                name="address_2"
                                required={!!isPH}
                                type="textarea"
                                label="Line 2"
                                placeholder={isPH ? 'Barangay or District, Region' : ''}
                                register={register}
                            />
                            <ErrorMessage name="address_2" errors={errors} />
                        </div>
                    </div>
                    <div className="col-span-full md:col-span-4">
                        {isPH ? (
                            <Controller
                                name="city"
                                defaultValue=""
                                control={control}
                                render={({ field: { onChange, ...field } }) => (
                                    <Datalist
                                        field={field}
                                        list="cityList"
                                        label="City"
                                        required={true}
                                        onChange={(e) => {
                                            onChange(e);
                                            const v = e.target.value;
                                            const state = states.find(
                                                (s) =>
                                                    s.name.toLowerCase() ===
                                                    v.toLowerCase()
                                            );
                                            if (state) {
                                                setValue(
                                                    'province',
                                                    state.regionName,
                                                    { shouldValidate: true }
                                                );
                                                setValue(
                                                    'country',
                                                    state.countryCode,
                                                    { shouldValidate: true }
                                                );
                                            } else {
                                                setValue('province', '');
                                            }
                                        }}
                                    >
                                        <datalist id="cityList">
                                            {states.map((s) => (
                                                <option key={s.id}>
                                                    {s.name}
                                                </option>
                                            ))}
                                        </datalist>
                                    </Datalist>
                                )}
                            />
                        ) : (
                            <Input
                                name="city"
                                label="City"
                                required
                                register={register}
                            />
                        )}

                        <ErrorMessage name="city" errors={errors} />
                    </div>
                    <div className="col-span-full md:col-span-3">
                        <Input
                            name="zipcode"
                            label={`${isPH ? 'Zip' : 'Postal'} Code`}
                            register={register}
                            placeholder={isPH ? 'Zip Code' : ''}
                        />
                        <ErrorMessage name="zipcode" errors={errors} />
                    </div>
                    <div className="col-span-full">
                        <Input
                            name="province"
                            label={isPH ? 'Province' : 'Region'}
                            required
                            register={register}
                            placeholder={isPH ? 'Province' : ''}
                        />
                        <ErrorMessage name="province" errors={errors} />
                    </div>
                    <div className="col-span-full">
                        <Select
                            label="Country"
                            name="country"
                            readOnly={isPH}
                            required
                            defaultValue="PH"
                            register={register}
                            options={countries}
                        />
                        <ErrorMessage name="country" errors={errors} />
                    </div>
                    <div className="col-span-full">
                        <Controller
                            name="survey_channel"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, ...field } }) => (
                                <Datalist
                                    field={field}
                                    list="surveyChannelList"
                                    label="How did you hear about us?"
                                    required={true}
                                    onChange={(e) => { onChange(e); }}
                                    placeholder="Select an option or type a different response"
                                >
                                    <datalist id="surveyChannelList">
                                        {surveyChannels.map((c, i) => (
                                            <option key={i}>
                                                {c}
                                            </option>
                                        ))}
                                    </datalist>
                                </Datalist>
                            )}
                        />
                        <ErrorMessage name="survey_channel" errors={errors} />
                    </div>
                    <div className="col-span-full">
                        <Input
                            name="tin"
                            label="TIN"
                            subLabel="(Tax Identification Number)"
                            placeholder="000-000-000-000"
                            register={register}
                            maxLength={15}
                            pattern={{
                                value: /^\d{3}-\d{3}-\d{3}-\d{3}$/,
                                message: 'Invalid TIN number format',
                            }}
                        />
                        <ErrorMessage name="tin" errors={errors} />
                    </div>
                    <div className="flex items-start col-span-full bg-blue-100 p-5 rounded">
                        <img src={InfoIcon} />
                        <p className="text-xs ml-3" style={{color: '#115C8E'}}>
                            Your donation is tax deductible in the Philippines.
                            By indicating your TIN, your donation will be covered by a
                            Certificate of Donation issued in your name.
                        </p>
                    </div>
                    {/* <div className="col-span-full flex items-center">
                        <input
                            type="checkbox"
                            id="hide_tin"
                            {...register('hide_tin', {
                                defaultValue: false,
                            })}
                        />
                        <label
                            className="ml-2 input-label mb-0"
                            htmlFor="hide_tin"
                        >
                            I prefer not to provide my TIN
                        </label>
                    </div> */}
                </div>

                <h2 className="text-xl font-bold mb-3">Select Payment Method</h2>
                <p className="text-sm">Select a method from below to donate</p>
                <hr className="border-gray-300 my-4" />
                <div className="space-y-4 mb-4">
                    {donationChannels.map((m, i) => (
                        <label
                            key={i}
                            htmlFor={m.value}
                            className="p-3 sm:px-5 sm:py-3 text-sm sm:text-base shadow-sm rounded bg-white flex items-center"
                            style={{border:'1px solid #828285'}}
                        >
                            <input
                                className="text-primary"
                                id={m.value}
                                type="radio"
                                {...register('donation_type', {
                                    required: true,
                                })}
                                value={m.value}
                            />
                            <span
                                className={`payment-method-label ml-2 ${
                                    ['gcash', 'paypal', 'give2asia'].includes(m.value)
                                        ? 'sr-only'
                                        : ''
                                }`}
                            >
                                {m.label}
                            </span>
                            <PaymentLogo value={m.value} />
                        </label>
                    ))}
                    <ErrorMessage name="donation_type" errors={errors} />
                </div>

                <div className="px-5 py-8 mb-4 flex items-center bg-primary-lightest border-l-4 border-primary">
                    <span className="text-sm">Your Donation:</span>
                    <span className="font-bold ml-auto">
                        {Intl.NumberFormat('en', {
                            style: 'currency',
                            currency: currency,
                        }).format(amt)}
                    </span>
                </div>

                {donationType === 'globalpay' && (
                    <div className="px-5 py-8 mb-4 flex items-center bg-primary-lighter border-l-4 border-primary">
                        <span className="text-sm">
                            You will be able to set the billing information
                            separately in the next screen.
                        </span>
                    </div>
                )}

                <div className="flex flex-col mb-2">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id="privacy_policy"
                            {...register('privacy_policy', {
                                defaultValue: false,
                                required: true,
                            })}
                        />
                        <label
                            className="ml-3 text-sm"
                            htmlFor="privacy_policy"
                        >
                            I have read and agree to Ayala Foundation's {' '}
                            <a href="https://ayalafoundation.org/privacy-policy"
                                className="text-secondary underline"
                                target="_blank">
                                Data Privacy Policy
                            </a>{'.'}
                        </label>
                    </div>
                    <ErrorMessage name="privacy_policy" errors={errors} />
                </div>
                <div className="flex flex-col mb-2">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id="newsletter"
                            {...register('newsletter', {
                                defaultValue: false,
                            })}
                        />
                        <label
                            className="ml-3 text-sm"
                            htmlFor="newsletter"
                        >
                            I would like to receive news and updates from
                            Ayala Foundation.
                        </label>
                    </div>
                </div>
                {/* <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="unsubscribe"
                        {...register('unsubscribe', {
                            defaultValue: false,
                        })}
                    />
                    <label className="ml-3 text-sm" htmlFor="unsubscribe">
                        I prefer not to receive any updates on my donation.
                    </label>
                </div> */}
                <button
                    type="submit"
                    disabled={!isDirty || !isValid}
                    className="disabled:opacity-50 flex items-center justify-center w-full py-3 px-5 mt-4 bg-primary text-white rounded border-primary"
                >
                    <HeartIcon className="h-5 w-5" />
                    <span className="ml-2">Send Donation</span>
                </button>
            </div>
        </form>
    );
};

export default DonateForm;

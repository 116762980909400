import React from 'react';
import { render } from 'react-dom';
import 'stylesheets/main.css';
import 'stylesheets/layout.scss';
import optionEquivalent from 'optionEquivalent';
import DonateForm from 'components/DonateForm';
import getMeta from 'utils/getMeta';

document.addEventListener('turbolinks:load', function () {
    const donationItems = document.getElementsByClassName('js-donation');
    const donationInput = document.getElementById('donation_amount');
    const donationEl = document.getElementById('your_donation');
    const preferenceCheckbox = document.getElementById('info_cod');

    [...donationItems].forEach((item) => {
        item.addEventListener('change', (e) => {
            const tId = `tab-${e.target.value}`;
            const tabs = document.getElementsByClassName('js-tab');
            [...tabs].forEach((tab) => tab.classList.remove('d-block'));
            const t = document.getElementById(tId);
            t.classList.add('d-block');
        });
    });

    const donorFields = document.querySelectorAll('.js-donor-fields input');
    [...donorFields].forEach((f) => {
        f.addEventListener('change', function (event) {
            const el = event.target;
            const targetField = el.dataset.target;
            const field = document.querySelector(`#${targetField}`);
            if (el['type'] === 'checkbox') {
                field.value = el.checked;
            } else {
                field.value = el.value;
            }
        });
    });

    donationInput &&
        donationInput.addEventListener('change', function (event) {
            const { value } = event.target;
            const displayedValue = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'Php',
            }).format(Number(value) || 0);
            donationEl.innerHTML = displayedValue;
        });

    preferenceCheckbox &&
        preferenceCheckbox.addEventListener('change', function (event) {
            const field = document.getElementById('info_tin');
            field.toggleAttribute('required');
        });

    const donateFormEl = document.getElementById('DonateForm');
    if (donateFormEl) {
        const {
            donation,
            donationOptions,
            page,
            countries,
            donationChannels,
            states,
            surveyChannels,
        } = donateFormEl.dataset;
        const token = getMeta('csrf-token');
        render(
            <DonateForm
                donation={JSON.parse(donation)}
                donationOptions={JSON.parse(donationOptions)}
                donationChannels={JSON.parse(donationChannels)}
                page={JSON.parse(page)}
                countries={JSON.parse(countries)}
                states={JSON.parse(states)}
                token={token}
                surveyChannels={JSON.parse(surveyChannels)}
            />,
            donateFormEl
        );
    }
});

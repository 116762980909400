import React, { Fragment } from 'react';

const Datalist = ({
    name,
    label,
    subLabel,
    required,
    children,
    list = null,
    field,
    onChange,
    placeholder = '',
}) => (
    <Fragment>
        <label htmlFor={name} className="input-label">
            {label}{' '}
            {subLabel && (
                <span className="text-xs font-normal text-gray-400">
                    {subLabel}
                </span>
            )}
            {required && <span className="text-red-500">*</span>}
        </label>
        <input
            type="text"
            id={name}
            className="input-field"
            list={list}
            {...field}
            onChange={onChange}
            placeholder={placeholder}
        />
        {children}
    </Fragment>
);

export default Datalist;

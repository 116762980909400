import React, { Fragment } from 'react';

const Select = ({ options, required, label, register, name, readOnly, defaultValue = '' }) => {
  const isArray = Object.prototype.toString(options) === "[object Array]";

  return (
    <Fragment>
        <label className="input-label">
            {label}
            {required && <span className="text-red-500"> *</span>}
        </label>
        {
          readOnly ? (
                      <Fragment>
                          <input type="text" className="hidden" value={defaultValue} {...register(name, { required })}></input>
                          <input type="text" className="input-field" defaultValue={options[defaultValue]} readOnly={readOnly}></input>
                      </Fragment>
                      )
                   : (<select
                       className="input-field"
                       defaultValue={defaultValue}
                       {...register(name, { required })}>
                       <option value="" hidden>Choose One</option>
                       { isArray ? options.map((opt, i) => (<option key={i} value={opt}>{opt}</option>))
                                 : Object.keys(options).map((key, i) => (<option key={i} value={key}>{options[key]}</option>))}
                       </select>)
         }
    </Fragment>
    );
}

export default Select;

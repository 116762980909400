export function isSafari() {
    return (
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === '[object SafariRemoteNotification]';
        })(
            !window['safari'] ||
                (typeof safari !== 'undefined' &&
                    window['safari'].pushNotification)
        )
    );
}

export function isIE(params) {
    return /*@cc_on!@*/ false || !!document.documentMode;
}

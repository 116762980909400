import React from 'react';
import VisaLogo from '../images/visa-logo.png';
import PaypalLogo from '../images/paypal-logo.png';
import GcashLogo from '../images/gcash-logo.png';
import MastercardLogo from '../images/mastercard-logo.svg';
import BpiLogo from '../images/bpi.png';
import BdoLogo from '../images/bdo.png';
import MbLogo from '../images/mb.png';
import BankLogos from '../images/bank-logos.png';
import CardLogos from '../images/card-logos.png';
import Give2AsiaLogo from '../images/Give2Asia_hires.png';

const PaymentLogo = ({ value }) => {
    switch (value) {
        case 'dragonpay':
            return (
                <div className="ml-auto flex items-center">
                    <img src={BankLogos} className="w-44" />
                </div>
            );
        case 'globalpay':
            return (
                <div className="ml-auto flex items-center">
                    <img src={CardLogos} className="w-20" />
                </div>
            );
        case 'gcash':
            return <img src={GcashLogo} alt="GCash logo" className="h-8" />;
        case 'paypal':
            return (
                <img src={PaypalLogo} alt="GCash logo" className="ml-2 h-6" />
            );
        case 'give2asia':
            return (
                <div className="flex flex-row justify-between w-full gap-x-6 items-center">
                    <img src={Give2AsiaLogo} alt="GCash logo" className="ml-2 h-6" />
                    <span class="text-xs sm:text-sm">Tax benefits available for US-based donors</span>
                </div>
            );
        default:
            return null;
    }
};

export default PaymentLogo;

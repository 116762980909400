import React, { Fragment } from 'react';

const Input = ({
    name,
    register,
    label,
    subLabel,
    required,
    type = 'text',
    placeholder = '',
    defaultValue = '',
    disabled,
    readOnly,
    pattern,
    maxLength,
    children,
    list = null,
}) => (
    <Fragment>
        <label htmlFor={name} className="input-label">
            {label}{' '}
            {subLabel && (
                <span className="text-xs font-normal text-gray-400">
                    {subLabel}
                </span>
            )}
            {required && <span className="text-red-500">*</span>}
        </label>
        {type === 'textarea' ? (
            <textarea
                id={name}
                className="input-field"
                placeholder={placeholder}
                defaultValue={defaultValue}
                disabled={disabled}
                readOnly={readOnly}
                rows="3"
                {...register(name, { required })}
            ></textarea>
        ) : (
            <input
                type={type}
                id={name}
                className="input-field"
                placeholder={placeholder}
                defaultValue={defaultValue}
                disabled={disabled}
                readOnly={readOnly}
                maxLength={maxLength}
                list={list}
                autoComplete={list ? 'off' : ''}
                {...register(name, { required, pattern })}
            />
        )}
        {children}
    </Fragment>
);

export default Input;

import React from 'react';

const ErrorMessage = ({ name, errors }) => {
    const error = name.split('.').reduce((acc, cur) => acc[cur], errors);
    if (!error) return null;

    const { type, message } = error;

    return (
        <div className="p-1 text-sm text-red-500 whitespace-pre-line">
            {type === 'required' ? 'This field is required' : message}
        </div>
    );
};

export default ErrorMessage;
